var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-title flex-row-space-between"},[_c('div',{staticClass:"title"},[_c('el-checkbox',{model:{value:(_vm.item.checked),callback:function ($$v) {_vm.$set(_vm.item, "checked", $$v)},expression:"item.checked"}}),_c('span',{staticClass:"vul-name",attrs:{"title":_vm.item.vul_name},on:{"click":function($event){return _vm.goDetail(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.vul_name)+" ")])],1),_c('div',{staticClass:"time"},[_c('i',{staticClass:"iconfont iconlatesttime"}),_vm._v(" "+_vm._s(_vm.fmtTime(_vm.item.create_time))+" ")])]),_c('div',{staticClass:"card-content"},[(_vm.item.payload)?_c('div',{staticClass:"card-content-item-box"},[_c('div',{staticClass:"label"},[_vm._v("payload")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.item.payload)+" ")])]):_vm._e(),_c('div',{staticClass:"card-content-item"},[_c('div',{staticClass:"title"},[_vm._v("请求信息")]),_c('div',{staticClass:"httpRequest"},[_c('MyMarkdownIt',{attrs:{"content":_vm.getReq(
                (_vm.item.request_messages &&
                  _vm.item.request_messages[0] &&
                  _vm.item.request_messages[0].request) ||
                  '--'
              )}})],1)]),_c('div',{staticClass:"infoLine flex-row-space-between"},[_c('div',[_c('span',{staticClass:"info"},[_c('i',{staticClass:"iconfont iconproject1",staticStyle:{"color":"#2563EB"}}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.item.project_name,"placement":"top-start","disabled":_vm.item.project_name.length <= 11}},[_c('span',{staticClass:"dot",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/project/appDetail/' + _vm.item.project_id)}}},[_vm._v(" "+_vm._s(_vm.item.project_name))])])],1),_c('span',{staticClass:"info version"},[_c('i',{staticClass:"iconfont iconversion"}),_vm._v(" "+_vm._s(_vm.item.project_version_name.substring(1))+" ")]),_c('span',{staticClass:"info",staticStyle:{"margin":"0 24px"}},[_c('i',{staticClass:"iconfont iconrisk",style:(_vm.item.vul_level_id === 1
              ? { color: 'var(--color-risk-high)' }
              : _vm.item.vul_level_id === 2
              ? { color: 'var(--color-risk-med)' }
              : _vm.item.vul_level_id === 3
              ? { color: 'var(--color-risk-low)' }
              : _vm.item.vul_level_id === 4
              ? { color: 'var(--color-risk-no)' }
              : _vm.item.vul_level_id === 5
              ? { color: 'var(--color-risk-tip)' }
              : '')}),_vm._v(" "+_vm._s(_vm.switchLevel(_vm.item.vul_level_id))+" ")]),_c('span',{staticClass:"info"},[_c('i',{staticClass:"iconfont iconclock-fill",staticStyle:{"color":"#4B5563","margin-right":"8px"}}),_vm._v(" "+_vm._s(_vm.item.latest_time)+" ")])]),_c('div')])])])}
var staticRenderFns = []

export { render, staticRenderFns }