





























































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
import Sync from './sync.vue'
@Component({ name: 'ScaCard', components: { Sync } })
export default class ScaCard extends VueBase {
  @Prop() item: any
  @Prop() searchObj: any
  @Prop() settingInte: any
  @Prop() getTableData: any

  private open = false

  fmtTime(str: any) {
    return formatTimestamp(str)
  }

  get CVENUMBERS() {
    const res = []
    for (let key in this.item.vul_cve_nums) {
      switch (key) {
        case 'cnnvd':
          res.push({
            label: this.item.vul_cve_nums[key],
            link: `http://www.cnnvd.org.cn/web/xxk/ldxqById.tag?CNNVD=${this.item.vul_cve_nums[key]}`,
          })
          break
        case 'cnvd':
          res.push({
            label: this.item.vul_cve_nums[key],
            link: `https://www.cnvd.org.cn/flaw/show/${this.item.vul_cve_nums[key]}`,
          })
          break
        case 'cve':
          res.push({
            label: this.item.vul_cve_nums[key],
            link: `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${this.item.vul_cve_nums[key]}`,
          })
          break
        case 'cwe':
          res.push({
            label: this.item.vul_cve_nums[key],
            link: `https://cwe.mitre.org/data/definitions/${this.item.vul_cve_nums['cwe_num']}.html`,
          })
          break
      }
    }
    return res
  }

  openWindow(url: string) {
    window.open(url)
  }

  switchAva(availability: any) {
    switch (availability) {
      case 1:
        return '存在利用代码'
      case 2:
        return '存在分析文章'
      case 3:
        return '无利用信息'
    }
  }

  switchLanguage(language: number) {
    switch (language) {
      case 1:
        return 'JAVA'
      case 2:
        return 'PYTHON'
      case 3:
        return 'PHP'
      case 4:
        return 'G0'
    }
  }
  switchLevel(level: number) {
    switch (level) {
      case 1:
        return '高危'
      case 2:
        return '中危'
      case 3:
        return '低危'
      case 4:
        return '无风险'
    }
  }

  switchLevelTag(level: number) {
    switch (level) {
      case 1:
        return '高'
      case 2:
        return '中'
      case 3:
        return '低'
      case 0:
        return '无'
    }
  }

  switchLevelClass(level: number) {
    switch (level) {
      case 1:
        return 'high'
      case 2:
        return 'middle'
      case 3:
        return 'low'
      case 0:
        return 'none'
    }
  }

  switchServerType(serverType: string) {
    switch (serverType) {
      case 'tomcat':
        return 'icontomcat'
      case 'jetty':
        return 'iconJetty'
      case 'resin':
        return 'iconresin'
      case 'weblogic':
        return 'iconwebLogic'
      case 'websphere':
        return 'iconwebSphere'
      case 'jboss':
        return 'iconJBoss'

      default:
        return 'iconyingyong'
    }
  }
  private goDetail(item: any) {
    this.$router.push(
      `/vuln/scaDetail/${item.id}/1?status=` +
        this.searchObj.status +
        '&id=' +
        item.id
    )
  }
}
