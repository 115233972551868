

















































































































































































































































































































import { Component, Watch } from 'vue-property-decorator'
import { formatTimestamp, getPassedTime } from '@/utils/utils'
import VueBase from '@/VueBase'
// import VulnCard from './components/vulnCard.vue'
import ScaCard from './components/ScaCard.vue'
import ScanCard from './components/ScanCard.vue'
import Sync1 from './components/sync1.vue'

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'VulnList', components: { ScaCard, ScanCard, Sync1 } })
export default class VulnList extends VueBase {
  private inteRules = {
    obj: [{ required: true, message: '请选择应用', trigger: 'blur' }],
    title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
    type: [{ required: true, message: '请选择缺陷类型', trigger: 'blur' }],
    level: [{ required: true, message: '请选择缺陷等级', trigger: 'blur' }],
  }
  private projectOtions: any = []
  private typeOptions: any = []
  private priorityOtions: any = []
  private settingInte: any = []
  private activeType: any = ''
  private pageInfo = {}
  private statusSelect: any = ''
  private alltotal: any = 0

  private goDetail(item: any) {
    this.$router.push(
      `/project/leakDetail/1/${item.id}?status=` +
        this.searchObj.status +
        '&id=' +
        item.id +
        '&backTitle='
    )
  }

  private chooseItems: Array<any> = []

  private handleSelectionChange(values: any) {
    this.chooseItems = values

    // const flag =
    //   this.tableData.length > 0 && this.tableData.every((item) => item.checked)
    // if (flag) {
    //   this.tableData.forEach((item) => this.$set(item, 'checked', false))
    // } else {
    //   this.tableData.forEach((item) => this.$set(item, 'checked', true))
    // }
    // console.log(this.tableData)
  }
  fitlerData: any = {
    scaNames:[],
    scaStatus:[],
    scaProjects:[],
    filterScaNames:[],
  }
  filterHandler(value: any, row: any, column: any) {
    if (value.hasOwnProperty('scaNames')){
      // this.fitlerData.scaNames = value.scaNames
      this.searchObj.level_str = value.scaNames
    }
    if (value.hasOwnProperty('scaStatus')){
      // this.fitlerData.scaStatus = value.scaStatus
      this.searchObj.status_str = value.scaStatus
    }
    if (value.hasOwnProperty('scaProjects')){
      // this.fitlerData.scaProjects = value.scaProjects
       this.searchObj.project_str = value.scaProjects
    }
    if (value.hasOwnProperty('filterScaNames')){
      // this.fitlerData.filterScaNames = value.filterScaNames
      this.searchObj.hook_type_str = value.filterScaNames
    }

    // if (value.scaNames && value.scaNames.length > 0) {
    //   this.searchObj.level_str = value.scaNames
    // } else {
    //   this.searchObj.level_str = []
    // }

    // if (value.scaStatus && value.scaStatus.length > 0) {
    //   this.searchObj.status_str = value.scaStatus
    // } else {
    //   this.searchObj.status_str = []
    // }

    // // todo
    // if (value.filterScaNames && value.filterScaNames.length > 0) {
    //   this.searchObj.hook_type_str = value.filterScaNames
    // } else {
    //   this.searchObj.hook_type_str = []
    // }

    // if (value.scaProjects && value.scaProjects.length > 0) {
    //   this.searchObj.project_str = value.scaProjects
    // } else {
    //   this.searchObj.project_str = []
    // }

    this.newSelectData()
  }

  private searchOrderList: any = [
    {
      label: '默认排序',
      value: null,
    },
    {
      label: '升序',
      value: true,
    },
    {
      label: '降序',
      value: false,
    },
  ]

  private resetData() {
    // this.page = 1
    // this.tableData = []

    this.searchObj.project_str = []
    this.searchObj.order_type = ''
    this.searchObj.keywords = ''
    this.searchObj.hook_type_str = []
    this.searchObj.sort = null

    // this.searchObj.level_str = []
    // this.searchObj.project_str = ''
    // this.searchObj.order_type = ''
    // this.searchObj.keywords = ''
    // this.searchObj.status_str = []
    // this.searchObj.source_type_str = []
    // this.searchObj.availability_str = []
    // this.searchObj.hook_type_str = []
    // this.searchObj.language_str = []
    // this.searchObj.sort = null
    this.newSelectData()
  }

  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }
  handleCurrentChange(val: number) {
    // console.log(this.dataEnd, val)
    // if (val > this.page) {
    //   if (!this.dataEnd) {
    //     this.page = val
    //     this.getTableData()
    //   }
    // } else {
    this.page = val
    this.getTableData()
    // }
  }

  private searchHookTypeTableData(item: any) {
    this.searchOptionsObj.hook_type.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.hook_type_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.hook_type_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.hook_type_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.hook_type_str = result
        }
      }
    })
    this.newSelectData()
  }

  private searchStatusTableData(item: any) {
    this.searchOptionsObj.status.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.status_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.status_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.status_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.status_str = result
        }
      }
    })
    this.newSelectData()
  }

  private searchTableData(item: any) {
    this.searchOptionsObj.level.map((item1: any) => {
      if (item1.id == item.id) {
        if (!item.cur) {
          const items: any = this.searchObj.level_str
          item1.cur = true
          items.push(item1.id)
          this.searchObj.level_str = items
        } else {
          item1.cur = false
          const items = this.searchObj.level_str
          const result = items.filter((item: any) => item != item1.id)
          this.searchObj.level_str = result
        }
      }
    })
    // this.getTableData(true)
    this.newSelectData()
  }

  async changeStatus(e: any) {
    this.$nextTick(() => {
      this.statusSelect = ''
    })

    if (this.chooseItems.length <= 0) {
      this.$message.warning('请选择需要更改状态的漏洞')
      return
    }

    const ids = this.chooseItems
      .map((item: any) => {
        return item.id
      })
      .filter((item1: any) => item1)

    // if (!this.tableData.some((item: any) => item.checked)) {
    //   this.$message.warning('请选择需要更改状态的漏洞')
    //   return
    // }
    // const ids = this.tableData
    //   .map((item) => {
    //     if (item.checked) {
    //       return item.id
    //     }
    //   })
    //   .filter((item) => item)
    const res = await this.services.vuln.changeStatus({
      vul_ids: ids,
      status_id: e,
    })

    if (res.status === 201) {
      this.$message.success('漏洞状态变更成功')
      this.newSelectData()
      return
    }
    this.$message.error('漏洞状态变更失败，请再次尝试')
  }

  private async summary() {
    const res = await this.services.vuln.summary()
    if (res.status === 201) {
      this.settingInte = res.data
    }
  }

  private getLevelInfo(level_id: any) {
    switch (level_id) {
      case 1:
        return {
          className: 'height',
          levelName: '高危',
        }
      case 2:
        return {
          className: 'middle',
          levelName: '中危',
        }
      case 3:
        return {
          className: 'low',
          levelName: '高危',
        }
      case 5:
        return {
          className: 'info',
          levelName: '提示',
        }
    }
  }

  private page = 1
  private pageSize = 20
  private dataEnd = false
  private tableData: Array<any> = []
  private searchOptionsObj: any = {
    filterProjects: [],
    filterHookTypes: [],
    filterStatus: [
      {
        text: '待验证',
        value: 1,
      },
      {
        text: '验证中',
        value: 2,
      },
      {
        text: '已确认',
        value: 3,
      },
      {
        text: '已忽略',
        value: 4,
      },
      {
        text: '已处理',
        value: 5,
      },
    ],
    filterLevel: [
      {
        text: '高危',
        value: 1,
      },
      {
        text: '中危',
        value: 2,
      },
      {
        text: '低危',
        value: 3,
      },
      {
        text: '无风险',
        value: 4,
      },
      {
        text: '提示',
        value: 5,
      },
    ],
    level: [
      {
        name: '高危',
        num: '0',
        id: 1,
        cur: false,
      },
      {
        name: '中危',
        num: '0',
        id: 2,
        cur: false,
      },
      {
        name: '低危',
        num: '0',
        id: 3,
        cur: false,
      },
      {
        name: '无风险',
        num: '0',
        id: 4,
        cur: false,
      },
      {
        name: '提示',
        num: '0',
        id: 5,
        cur: false,
      },
    ],
    status: [
      {
        name: '待验证',
        num: '0',
        id: 1,
      },
      {
        name: '验证中',
        num: '0',
        id: 2,
      },
      {
        name: '已确认',
        num: '0',
        id: 3,
      },
      {
        name: '已忽略',
        num: '0',
        id: 4,
      },
      {
        name: '已处理',
        num: '0',
        id: 5,
      },
    ],
    projects: [],
    vul_source: [],
    availability: [],
    hook_type: [],
    language: [
      {
        name: 'JAVA',
        num: 0,
        id: 1,
      },
      {
        name: 'PYTHON',
        num: 0,
        id: 2,
      },
      {
        name: 'PHP',
        num: 0,
        id: 3,
      },
      {
        name: 'GO',
        num: 0,
        id: 4,
      },
    ],
    orderOptions: [
      {
        label: '漏洞级别',
        value: 1,
      },
      {
        label: '发现时间',
        value: 2,
      },
      {
        label: '最新活跃',
        value: 3,
      },
      {
        label: '状态',
        value: 4,
      },
    ],
    statusOptions: [],
  }

  private async getStatus() {
    const res = await this.services.vuln.vulStatus()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.searchOptionsObj.filterStatus = res.data.map((item:any) => {
      return {
        value: item.id,
        text: item.name,
      }
    })
    this.searchOptionsObj.statusOptions = res.data.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }

  private searchObj: any = {
    level_str: [],
    // project_str: '',
    project_str: [],
    order_type: '',
    keywords: '',
    status_str: [],
    source_type_str: [],
    availability_str: [],
    hook_type_str: [],
    language_str: [],
    sort: null,
  }

  openInNew() {
    const route: any = this.$router.resolve(
      `/project/leakDetail/1/${this.rightClickItem.id}?status=` +
        this.searchObj.status +
        '&id=' +
        this.rightClickItem.id
    )
    window.open(route.href, '_blank')
  }

  top: any = 0
  left: any = 0
  visible: any = false
  rightClickItem: any = {}

  @Watch('visible', { immediate: true })
  visibleFun(value: any) {
    if (value) {
      document.body.addEventListener('click', this.closeMenu)
    } else {
      document.body.removeEventListener('click', this.closeMenu)
    }
  }

  openMenu(e: any, item: any) {
    this.rightClickItem = item

    var x = e.pageX
    var y = e.pageY

    this.top = y
    this.left = x

    this.visible = true
  }
  closeMenu() {
    this.visible = false
  }

  async created() {
    if (this.$route.query.level_id) {
      this.searchObj.level_str = [this.$route.query.level_id]
    }

    if (this.$route.query.hook_type_str) {
      this.searchObj.hook_type_str = [this.$route.query.hook_type_str]
    }

    this.getStatus()
    this.getTableData()
    this.vulnSummary()
    this.summary()
    this.getProjects()
  }

  private async querySearchAsync(queryString: string, cb: any) {
    const res = await this.services.setting.searchProject({ name: queryString })
    if (res.status === 201) {
      const data = res.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id,
        }
      })
      cb(data)
    }
  }

  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }

  private deleteVul(type: string) {
    this.$confirm(
      '该操作将会删除选中漏洞',
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (!this.tableData.some((item: any) => item.checked)) {
        this.$message({
          type: 'warning',
          message: '请选择需要删除的漏洞',
          showClose: true,
        })
        return
      }
      const ids = this.tableData
        .map((item) => {
          if (item.checked) {
            return item.id
          }
        })
        .filter((item) => item)
      res = await this.services.vuln.vulListDelete({
        source_type: 1,
        ids: String(ids),
      })

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private recheck(type: string) {
    this.$confirm(
      `${this.$t('views.vulnList.will')}${
        type === 'all'
          ? this.$t('views.vulnList.all')
          : this.$t('views.vulnList.batch')
      }${this.$t('views.vulnList.recheckDesc')}`,
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: this.$t(
          'views.vulnList.confirmButtonText'
        ) as string,
        cancelButtonText: this.$t('views.vulnList.cancelButtonText') as string,
        type: 'warning',
      }
    ).then(async () => {
      let res: any = {}
      if (type === 'all') {
        res = await this.services.vuln.vulRecheckAll({ type })
      } else {
        if (!this.tableData.some((item: any) => item.checked)) {
          this.$message({
            type: 'warning',
            message: this.$t('views.vulnList.chooseWarning') as string,
            showClose: true,
          })
          return
        }
        const ids = this.tableData
          .map((item) => {
            if (item.checked) {
              return item.id
            }
          })
          .filter((item) => item)
        res = await this.services.vuln.recheck({
          ids: String(ids),
        })
      }

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
        if (type !== 'all') {
          await this.newSelectData()
        }
      }
    })
  }

  private selectAll(e: any) {
    const flag =
      this.tableData.length > 0 && this.tableData.every((item) => item.checked)
    if (flag) {
      this.tableData.forEach((item) => this.$set(item, 'checked', false))
    } else {
      this.tableData.forEach((item) => this.$set(item, 'checked', true))
    }
    console.log(this.tableData)
  }

  private reset() {
    this.searchOptionsObj.hook_type.map((item1: any) => {
      item1.cur = false
    })

    this.searchOptionsObj.status.map((item1: any) => {
      item1.cur = false
    })
    this.searchOptionsObj.level.map((item1: any) => {
      item1.cur = false
    })

    this.searchObj.level_str = []
    this.searchObj.project_str = ''
    this.searchObj.order_type = ''
    this.searchObj.keywords = ''
    this.searchObj.status_str = []
    this.searchObj.source_type_str = []
    this.searchObj.availability_str = []
    this.searchObj.hook_type_str = []
    this.searchObj.language_str = []
    this.searchObj.sort = null
    this.newSelectData()
  }

  private newSelectData() {
    this.page = 1
    this.dataEnd = false
    this.tableData = []
    if (!this.searchObj.order_type) this.searchObj.sort = null
    this.getTableData()
  }

  timer: any = null
  private myScroll() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      const bottomWindow =
        document.documentElement.scrollTop + window.innerHeight >
        document.documentElement.offsetHeight - 1
      if (bottomWindow) {
        if (!this.dataEnd) {
          this.page += 1
          this.getTableData()
        }
      }
    }, 100)
  }

  private getItemTitle(item: any) {
    let title = ''
    title = `${item.uri}  ${item.http_method} 出现 ${item.strategy__vul_name}`
    if (item.taint_position) {
      title += ' 位置:' + item.taint_position
    }
    return title
  }

  private async getTableData(reflash = false) {
    // console.log('this.searchObj', this.searchObj)
    let sort = undefined
    if (this.searchObj.sort === true) {
      sort = 1
    }
    if (this.searchObj.sort === false) {
      sort = 0
    }
    let params
    params = {
      page: this.page,
      page_size: this.pageSize,
      level_id_str: this.searchObj.level_str.join(',') || undefined,
      order_type: this.searchObj.order_type || undefined,
      keywords: this.searchObj.keywords || undefined,
      // project_id_str: this.searchObj.project_str || undefined,
      project_id_str: this.searchObj.project_str.join(',') || undefined,
      status_id_str: this.searchObj.status_str.join(',') || undefined,
      source_type_str: this.searchObj.source_type_str.join(',') || undefined,
      availability_str: this.searchObj.availability_str.join(',') || undefined,
      hook_type_id_str: this.searchObj.hook_type_str.join(',') || undefined,
      language_str: this.searchObj.language_str.join(',') || undefined,
      order_type_desc: sort,
      type: 'vuln',
    }
    this.loadingStart()
    const { status, data, msg, page } = await this.services.vuln.vulListContent(
      params
    )
    setTimeout(() => {
      this.loadingDone()
    }, 200)
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const tableData = (data.messages || data).reduce(
      (list: Array<any>, item: any) => {
        let vul_number = ''
        if (item.vul_cve_nums) {
          vul_number = `${item.vul_cve_nums.cnnvd} | ${item.vul_cve_nums.cnvd} | ${item.vul_cve_nums.cve} | ${item.vul_cve_nums.cwe}`
        }
        // let title = ''
        // title = `${item.uri}  ${item.http_method} 出现 ${item.strategy__vul_name}`
        // if (item.taint_position) {
        //   title += ' 位置:' + item.taint_position
        // }
        list.push({
          ...item,
          first_time: formatTimestamp(item.first_time),
          latest_time: getPassedTime(item.latest_time),
          vul_number: vul_number,
          id: item.id,
          vul_name_title: item.strategy__vul_name,
          vul_name_info: `${item.taint_position} ${item.uri}`,
          latest_time_nyr: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )
    this.alltotal = data.page.alltotal || 0
    // if (tableData.length < this.pageSize) {
    //   this.dataEnd = true
    //   this.alltotal = this.page * this.pageSize - 1
    // } else {
    //   this.dataEnd = false
    //   this.alltotal = this.page * this.pageSize + 1
    // }
    // if (reflash) {
    this.tableData = []
    // }
    // this.pageInfo = page
    this.tableData = [...this.tableData, ...tableData]
  }
  private SummaryCatch: any = {}
  private async vulnSummary() {
    this.searchOptionsObj.level = []
    this.searchOptionsObj.vul_source = []
    this.searchOptionsObj.availability = []
    this.searchOptionsObj.hook_type = []
    this.searchOptionsObj.language = []
    this.searchOptionsObj.status = []
    this.searchOptionsObj.projects = []
    const params = {
      type: 'vuln',
    }
    this.searchOptionsObj.orderOptions = [
      {
        label: '漏洞级别',
        value: 1,
      },
      {
        label: '发现时间',
        value: 2,
      },
      {
        label: '最新活跃',
        value: 3,
      },
      {
        label: '状态',
        value: 4,
      },
    ]

    this.loadingStart()
    let res
    if (this.SummaryCatch['vuln']) {
      res = this.SummaryCatch['vuln']
    } else {
      res = await this.services.vuln.vulSummaryNum(params)
    }
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }

    this.SummaryCatch['vuln'] = res

    const availability = Object.values(res.data.messages.availability || {})
    this.searchOptionsObj.level =
      res.data.messages.level && res.data.messages.level.length
        ? res.data.messages.level
        : [
            {
              name: '高危',
              num: '0',
              id: 1,
              cur: false,
            },
            {
              name: '中危',
              num: '0',
              id: 2,
              cur: false,
            },
            {
              name: '低危',
              num: '0',
              id: 3,
              cur: false,
            },
            {
              name: '无风险',
              num: '0',
              id: 4,
              cur: false,
            },
            {
              name: '提示',
              num: '0',
              id: 5,
              cur: false,
            },
          ]
    this.searchOptionsObj.status =
      res.data.messages.status && res.data.messages.status.length
        ? res.data.messages.status
        : [
            {
              name: '待验证',
              num: '0',
              id: 1,
              cur: false,
            },
            {
              name: '验证中',
              num: '0',
              id: 2,
              cur: false,
            },
            {
              name: '已确认',
              num: '0',
              id: 3,
              cur: false,
            },
            {
              name: '已忽略',
              num: '0',
              id: 4,
              cur: false,
            },
            {
              name: '已处理',
              num: '0',
              id: 5,
              cur: false,
            },
          ]

    this.searchOptionsObj.availability =
      availability && availability.length
        ? availability
        : this.searchOptionsObj.availability

    const hook_types: any = []
    const filterHookTypes: any = []
    const data =
      res.data.messages.hook_type && res.data.messages.hook_type.length
        ? res.data.messages.hook_type
        : this.searchOptionsObj.hook_type
    // todo
    data.map((item: any) => {
      hook_types.push({
        ...item,
        cur: false,
      })

      filterHookTypes.push({
        text: item.name,
        value: item.id,
      })
    })
    this.searchOptionsObj.filterHookTypes = filterHookTypes
    this.searchOptionsObj.hook_type = hook_types
    // this.searchOptionsObj.hook_type =
    //   res.data.messages.hook_type && res.data.messages.hook_type.length
    //     ? res.data.messages.hook_type
    //     : this.searchOptionsObj.hook_type
    this.searchOptionsObj.language =
      res.data.messages.language && res.data.messages.language.length
        ? res.data.messages.language
        : [
            {
              name: 'JAVA',
              num: 0,
              id: 1,
            },
            {
              name: 'PYTHON',
              num: 0,
              id: 2,
            },
            {
              name: 'PHP',
              num: 0,
              id: 3,
            },
            {
              name: 'GO',
              num: 0,
              id: 4,
            },
          ]
    this.searchOptionsObj.projects =
      res.data.messages.project && res.data.messages.project.length
        ? res.data.messages.project
        : this.searchOptionsObj.projects
  }

  private projects: any = []
  private async getProjects(name?: string | undefined) {
    const res = await this.services.project.projectList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      // this.projects = res.data
      const data = res.data
      const filterProjects: any = []
      data.map((item: any) => {
        filterProjects.push({
          text: item.name,
          value: item.id,
        })
      })
      this.searchOptionsObj.filterProjects = filterProjects
    }
  }
  private async remoteProject(query: string) {
    this.getProjects(query)
  }

  private isExpand: boolean = false
  handleAccor() {
    this.isExpand = !this.isExpand
  }
}
